import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers/fetchWrapper';
import { useUserStore } from "@/stores/user";
import { useAuthStore } from "@/stores/auth";

export const useSectorStore = defineStore({
	id: 'sectors',
	state: () => ({
		sectors: [],
		sites: [],
	}),
	actions: {
		async getUserSectors() {
			var store = useUserStore();
			if (store.sectors.length > 0)
				return store.sectors;

			return fetchWrapper.get('/sectors').then((data) => {
				if (data) {
					store.sectors = data;
				}
				return data;
			});
		},
		async getAllSectors() {
			return fetchWrapper.get('/sectors', null, { include: 'all' })
				.then((data) => {
					if (data) {
						return data;
					}
				});
		},
		async getSectorsWithRegions() {
			return fetchWrapper.get('/sectors', null, { include_regions: 'true' })
				.then((data) => {
					if (data) {
						return data;
					}
				});
		},
		async getSectorSites(sectorId) {
			var store = useUserStore();
			if (!sectorId) {
				const Session = useAuthStore();
				sectorId = Session.getSectorSession();
			}

			return fetchWrapper.get(`/sectors/${sectorId}/sites`).then((data) => {
				if (data) {
					store.sites = data;
				}
				return data;
			});
		},
		async getAssignedAppsBySector(sectorId) {
			const session = useAuthStore();
			return fetchWrapper.get(`/sectors/${sectorId}/assigned-apps`)
				.then(data => {
					if(data) {
						session.setAppsSession(data);
					}
					return data;
				})
		},
		async createSector(data) {
			if (data){
				let payload = { sector: data }
				return fetchWrapper.post('/sectors', payload)
					.then(data => {
						if (data.id) {
							return { success: true, sector: data };
						}
						else 
							return data;
					});
			}
		},
		async updateSector(sectorId, data) {
			if (sectorId && data) {
				let payload = { sector: data }
				return fetchWrapper.put(`/sectors/${sectorId}`, payload)
					.then(data => {
						return { success: data.id == sectorId };
					});
			}
		},
		async deleteSector(sectorId) {
			if (sectorId)
				return fetchWrapper.delete(`/sectors/${sectorId}`)
					.then(() => {
						this.sectors = this.sectors.filter(function( sector ) {
							return sector.id !== sectorId;
						});
						return true;
					});
		}
	},
});
