import { useAuthStore } from '@/stores/auth';
// import { objectIncludes } from '@tiptap/vue-3';

const baseApiUrl = import.meta.env.VITE_API_URL;
export const fetchWrapper = {
  get: request('GET'),
  post: request('POST'),
  put: request('PUT'),
  delete: request('DELETE'),
  getImage: request('GET', handleImageResonse),
};

function request(method, responseHandler = handleJsonResponse) {
  return (path, body, params, bodyIsFormData = false) => {
    let url = `${baseApiUrl}${path}`;
    const requestOptions = {
      method,
      headers: path !== '/session' ? authHeader() : ''
    };
    
    if (body && !bodyIsFormData) {
      requestOptions.headers['Content-Type'] = 'application/json';
      requestOptions.body = JSON.stringify(body);
    }
    else 
      requestOptions.body = body 

    if (params) {
      let hasQuestionMark = url.includes('?');
      for (const property in params) {
        let prependChar = '&';
        if (!hasQuestionMark) {
          prependChar = '?';
          hasQuestionMark = true
        }
        url += `${prependChar}${property}=${params[property]}`;
      }
    }
    return fetch(url, requestOptions)
      .then((response) => responseHandler(response))
      .catch((error) => {
        console.log('Fetch error occurred:', error);
        // Add custom error handling if needed (e.g., show a message to the user)
        return {};
      });

  }
}

function handleUnauthorzedRedirect() {
	console.log('Unauthorized (401), logging out user');
	// Clear user data and redirect to login page
	// to clear all state and cookies
	const { logout } = useAuthStore();
	logout();
	// using window.location.href instead of router.push as a server side redirect is needed
	window.location.href = `${window.location.origin}/login`;
}

// helper functions
function authHeader() {
  // return auth header with jwt if user is logged in and request is to the api url
  const { user } = useAuthStore();
  if (user?.token) {
    return { Authorization: `Bearer ${user.token}` };
  } else {
    return {};
  }
}

function handleJsonResponse(response) {
  return response.text().then(text => {
    if (!response.ok) {
      // Check if the response status is 401
      if (response.status === 401) {
				handleUnauthorzedRedirect();
        return Promise.reject('Unauthorized');
      }
      // Handle other non-2xx responses
      return Promise.reject(response);
    }

    try {
      // Try to parse the response text if it's not empty
      const responseObject = text ? JSON.parse(text) : null;

      // Handle successful response with data or empty response
      if (!responseObject) {
        return { success: true }; // In case of an empty response
      }
      if (responseObject.data) {
        return responseObject.data;
      }
      return responseObject; // Return parsed response if no `data` field
    } catch (error) {
      // Handle parsing errors if the response is not valid JSON
      console.error('Error parsing JSON response:', error);
      return Promise.reject('Invalid JSON response');
    }
  });
}

function handleImageResonse(response) {
  return response.blob()
    .then(blob => {
      if (!response.ok) {
        // Check if the response status is 401
        if (response.status === 401) {
          handleUnauthorzedRedirect();
          return Promise.reject('Unauthorized');
        }
        // Handle other non-2xx responses
        return Promise.reject(response);
      }
      
    const responseObject = URL.createObjectURL(blob);
    return responseObject;
  });
}   