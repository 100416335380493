import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers/fetchWrapper';
import { useAuthStore } from '@/stores/auth';

export const useUserStore = defineStore({
	id: 'user',
	state: () => ({
		sectors: [],
		sectorOptions: [],
		sites: [],
		siteOptions: [],
		globalNodes: [],
		campusNodes: [],
		activeCampus: false,
	}),
	actions: {
		async getUserDetails() {
			const { user } = useAuthStore();
			return fetchWrapper.get(`/users/${user.id}`, {})
				.then(data => {
					if (data) {
						console.log(data);
					}
				});
		},
		clearUserData() {
			this.sectors = [];
			this.sectorOptions = [];
			this.sites = [],
			this.siteOptions = [],
			this.globalNodes = [],
			this.campusNodes = [],
			this.activeCampus = false;
			this.id = '';
			this.isAdmin = false;
		}
	}
});
