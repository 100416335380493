export const storageWrapper = {
	setItem: function (key, value) {
		// localStorage.setItem(key, btoa(value));
		localStorage.setItem(key, value);
	},
	getItem: function (key) {
		let data = localStorage.getItem(key);
		if (data) {
			// return atob(data);
			return data;
		} else {
			return '{}';
		}
	},
	clear() {
		localStorage.clear();
	},
	removeItem: function (key) {
		localStorage.removeItem(key);
	},
	removeAll: function () {
		localStorage.removeAll();
	},
};
