/*
 * Example Notifications
 */

export default [
	{
		href: 'javascript:void(0)',
		icon: 'fa fa-fw fa-check-circle text-success',
		title: 'You have a new follower',
		time: '15 min ago',
	},
	{
		href: 'javascript:void(0)',
		icon: 'fa fa-fw fa-plus-circle text-primary',
		title: '1 new sale, keep it up',
		time: '22 min ago',
	},
	{
		href: 'javascript:void(0)',
		icon: 'fa fa-fw fa-times-circle text-danger',
		title: 'Update failed, restart server',
		time: '26 min ago',
	},
	{
		href: 'javascript:void(0)',
		icon: 'fa fa-fw fa-plus-circle text-primary',
		title: '2 new sales, keep it up',
		time: '33 min ago',
	},
	{
		href: 'javascript:void(0)',
		icon: 'fa fa-fw fa-user-plus text-success',
		title: 'You have a new subscriber',
		time: '41 min ago',
	},
	{
		href: 'javascript:void(0)',
		icon: 'fa fa-fw fa-check-circle text-success',
		title: 'You have a new follower',
		time: '42 min ago',
	},
];
