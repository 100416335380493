<template>
  <RouterView :key="Session.selectedSiteId" />
</template>
<script setup>
import { watch } from 'vue';

import { useAuthStore } from "@/stores/auth";
const Session = useAuthStore();
// TODO: the :key makes the RouterView reload when the site is changing.
// how can we know if it's a global vs a campus route. Maybe we would have a session
// property that is set 
watch(
  () => Session.selectedSiteId,
  (newOption) => {
    console.log(newOption);
  }
);
</script>