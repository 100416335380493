import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "@/stores/auth";
import NProgress from "nprogress/nprogress.js";

// Main layouts
import LayoutTools from "@/layouts/variations/Tools.vue";
import LayoutSimple from "@/layouts/variations/Simple.vue";

// Frontend: Landing
const Login = () => import("@/views/auth/SignInView.vue");
const AuthForgotPassword = () => import("@/views/auth/ForgotPasswordView.vue");
const AuthSignUp = () => import("@/views/auth/SignUpView.vue");
const AuthResetPassword = () => import("@/views/auth/ResetPasswordView.vue");
const Error404 = () => import("@/views/errors/404View.vue");
const Error403 = () => import("@/views/errors/403View.vue");

// Backend: Dashboard
const Dashboard = () => import("@/views/tools/DashboardView.vue");
// Admin
const SiteManager = () => import("@/views/tools/global/admin/SiteManager.vue");
const Users = () => import("@/views/tools/global/admin/Users.vue");
// Platform Admin
const Apps = () => import("@/views/tools/global/platform-admin/Apps.vue");
const AppGroups = () =>
  import("@/views/tools/global/platform-admin/AppGroups.vue");
const Regions = () => import("@/views/tools/global/platform-admin/Regions.vue");
const Sectors = () => import("@/views/tools/global/platform-admin/Sectors.vue");
// Ordering
const CustomerFilters = () =>
  import("@/views/tools/campus/ordering/CustomerFilters.vue");
const MealPlanManager = () =>
  import("@/views/tools/campus/ordering/MealPlanManager.vue");
const StoreSettings = () =>
  import("@/views/tools/campus/ordering/StoreSettings.vue");
const Support = () => import("@/views/tools/global/help/Support.vue");

// Campus
// Site Admin
const Events = () => import("@/views/tools/campus/events/Events.vue");
const Locations = () => import("@/views/tools/campus/site-admin/Locations.vue");
const News = () => import("@/views/tools/campus/news/News.vue");
const SpecialOffers = () =>
  import("@/views/tools/campus/special-offers/SpecialOffers.vue");
const Staff = () => import("@/views/tools/campus/content-management/Staff.vue");
const GetAnswersNow = () =>
  import("@/views/tools/campus/content-management/GetAnswersNow.vue");

// Set all routes
const routes = [
  {
    path: "/",
    component: LayoutSimple,
    children: [
      {
        path: "login",
        name: "auth-signin",
        component: Login,
      },
      {
        path: "forgot-password",
        name: "auth-forgot-password",
        component: AuthForgotPassword,
      },
      {
        path: "reset-password/:publicKey",
        name: "auth-reset-password",
        component: AuthResetPassword,
        props: true,
      },
      {
        path: "signup",
        name: "auth-signup",
        component: AuthSignUp,
      },
      {
        path: "404",
        name: "error-404",
        component: Error404,
      },
      {
        path: "403",
        name: "error-403",
        component: Error403,
      },
    ],
  },
  {
    path: "/tools",
    redirect: "/tools/dashboard",
    component: LayoutTools,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard,
      },
      {
        path: "allergen-self-registration",
        name: "allergen-self-registration",
        component: Dashboard,
        meta: {
          type: "global",
          sub: "Reports",
        },
      },
      {
        path: "analytics-export",
        name: "analytics-export",
        component: Dashboard,
        meta: {
          type: "global",
          sub: "Reports",
        },
      },
      {
        path: "average-transaction-totals",
        name: "average-transaction-totals",
        component: Dashboard,
        meta: {
          type: "global",
          sub: "Reports",
        },
      },
      {
        path: "food-insecurity-export",
        name: "food-insecurity-export",
        component: Dashboard,
        meta: {
          type: "global",
          sub: "Reports",
        },
      },
      {
        path: "global-transactions",
        name: "global-transactions",
        component: Dashboard,
        meta: {
          type: "global",
          sub: "Reports",
        },
      },
      {
        path: "mobile-analytics",
        name: "mobile-analytics",
        component: Dashboard,
        meta: {
          type: "global",
          sub: "Reports",
        },
      },
      {
        path: "mobile-heatmap",
        name: "mobile-heatmap",
        component: Dashboard,
        meta: {
          type: "global",
          sub: "Reports",
        },
      },
      {
        path: "multiple-unit-analytics",
        name: "multiple-unit-analytics",
        component: Dashboard,
        meta: {
          type: "global",
          sub: "Reports",
        },
      },
      {
        path: "single-unit-analytics",
        name: "single-unit-analytics",
        component: Dashboard,
        meta: {
          type: "global",
          sub: "Reports",
        },
      },
      {
        path: "transaction-reconciliation",
        name: "transaction-reconciliation",
        component: Dashboard,
        meta: {
          type: "global",
          sub: "Reports",
        },
      },
      {
        path: "transaction-totals",
        name: "transaction-totals",
        component: Dashboard,
        meta: {
          type: "global",
          sub: "Reports",
        },
      },
      {
        path: "treasury-&-accounting",
        name: "treasury-&-accounting",
        component: Dashboard,
        meta: {
          type: "global",
          sub: "Reports",
        },
      },
      {
        path: "app-groups",
        name: "app-groups",
        component: AppGroups,
        meta: {
          type: "global",
          sub: "Platform Admin",
        },
      },
      {
        path: "apps",
        name: "apps",
        component: Apps,
        meta: {
          type: "global",
          sub: "Platform Admin",
        },
      },
      {
        path: "regions",
        name: "regions",
        component: Regions,
        meta: {
          type: "global",
          sub: "Platform Admin",
        },
      },
      {
        path: "sectors",
        name: "sectors",
        component: Sectors,
        meta: {
          type: "global",
          sub: "Platform Admin",
        },
      },
      {
        path: "unit-addresses",
        name: "unit-addresses",
        component: Dashboard,
        meta: {
          type: "global",
          sub: "Platform Admin",
        },
      },
      {
        path: "user-groups",
        name: "user-groups",
        component: Dashboard,
        meta: {
          type: "global",
          sub: "Platform Admin",
        },
      },
      {
        path: "concept-brands",
        name: "concept-brands",
        component: Dashboard,
        meta: {
          type: "global",
          sub: "Admin",
        },
      },
      {
        path: "concept-items",
        name: "concept-items",
        component: Dashboard,
        meta: {
          type: "global",
          sub: "Admin",
        },
      },
      {
        path: "global-sections",
        name: "global-sections",
        component: Dashboard,
        meta: {
          type: "global",
          sub: "Admin",
        },
      },
      {
        path: "landing-page-tiles",
        name: "landing-page-tiles",
        component: Dashboard,
        meta: {
          type: "global",
          sub: "Admin",
        },
      },
      {
        path: "master-section",
        name: "master-section",
        component: Dashboard,
        meta: {
          type: "global",
          sub: "Admin",
        },
      },
      {
        path: "mobile-settings",
        name: "mobile-settings",
        component: Dashboard,
        meta: {
          type: "global",
          sub: "Admin",
        },
      },
      {
        path: "notification-categories",
        name: "notification-categories",
        component: Dashboard,
        meta: {
          type: "global",
          sub: "Admin",
        },
      },
      {
        path: "site-manager/:id?",
        name: "site-manager",
        component: SiteManager,
        meta: {
          type: "global",
          sub: "Admin",
        },
      },
      {
        path: "site-settings",
        name: "site-settings",
        component: Dashboard,
        meta: {
          type: "global",
          sub: "Admin",
        },
      },
      {
        path: "site-users",
        name: "site-users",
        component: SiteManager,
        meta: {
          type: "global",
          sub: "Admin",
        },
      },
      {
        path: "users",
        name: "users",
        component: Users,
        meta: {
          type: "global",
          sub: "Admin",
        },
      },
      {
        path: "faqs",
        name: "faqs",
        component: Dashboard,
        meta: {
          type: "global",
          sub: "Help",
        },
      },
      {
        path: "suggestion-box",
        name: "suggestion-box",
        component: Dashboard,
        meta: {
          type: "global",
          sub: "Help",
        },
      },
      {
        path: "refunds",
        name: "refunds",
        component: Dashboard,
        meta: {
          type: "global",
          sub: "Help",
        },
      },
      {
        path: "refund-report",
        name: "refund-report",
        component: Dashboard,
        meta: {
          type: "global",
          sub: "Help",
        },
      },
      {
        path: "help",
        name: "help",
        component: Dashboard,
        meta: {
          type: "global",
          sub: "Help",
        },
      },
      {
        path: "support",
        name: "support",
        component: Support,
        meta: {
          type: "global",
          sub: "Help",
        },
      },
      {
        path: "feature-requests",
        name: "feature-requests",
        component: Dashboard,
        meta: {
          type: "global",
          sub: "Help",
        },
      },
      {
        path: "video-training",
        name: "video-training",
        component: Dashboard,
        meta: {
          type: "global",
          sub: "Help",
        },
      },
      {
        path: "allergen-filters",
        name: "allergen-filters",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Site Admin",
        },
      },
      {
        path: "concept-menus",
        name: "concept-menus",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Site Admin",
        },
      },
      {
        path: "faculty-email-validator",
        name: "faculty-email-validator",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Site Admin",
        },
      },
      {
        path: "feedback",
        name: "feedback",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Site Admin",
        },
      },
      {
        path: "food-insecurity",
        name: "food-insecurity",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Site Admin",
        },
      },
      {
        path: "legacy-menu-cycles",
        name: "legacy-menu-cycles",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Site Admin",
        },
      },
      {
        path: "legacy-menus",
        name: "legacy-menus",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Site Admin",
        },
      },
      {
        path: "locations",
        name: "locations",
        component: Locations,
        meta: {
          type: "campus",
          sub: "Site Admin",
        },
      },
      {
        path: "menu-builder",
        name: "menu-builder",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Site Admin",
        },
      },
      {
        path: "microsite",
        name: "microsite",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Site Admin",
        },
      },
      {
        path: "notification-manager",
        name: "notification-manager",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Site Admin",
        },
      },
      {
        path: "report-queue",
        name: "report-queue",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Site Admin",
        },
      },
      {
        path: "reservations-export",
        name: "reservations-export",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Site Admin",
        },
      },
      {
        path: "reservation-manager",
        name: "reservation-manager",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Site Admin",
        },
      },
      {
        path: "routing",
        name: "routing",
        component: () => import("@/views/tools/campus/site-admin/Routes.vue"),
        meta: {
          type: "campus",
          sub: "Site Admin",
        },
      },
      {
        path: "coffee-subscriptions",
        name: "coffee-subscriptions",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Site Admin",
        },
      },
      {
        path: "balanced-u-events",
        name: "balanced-u-events",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Wellness",
        },
      },
      {
        path: "blocked-student-accounts",
        name: "blocked-student-accounts",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Ordering",
        },
      },
      {
        path: "declined-orders",
        name: "declined-orders",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Ordering",
        },
      },
      {
        path: "store-settings",
        name: "store-settings",
        component: StoreSettings,
        meta: {
          type: "campus",
          sub: "Ordering",
        },
      },
      {
        path: "customer-filters",
        name: "customer-filters",
        component: CustomerFilters,
        meta: {
          type: "campus",
          sub: "Ordering",
        },
      },
      {
        path: "meal-plan-manager",
        name: "meal-plan-manager",
        component: MealPlanManager,
        meta: {
          type: "campus",
          sub: "Ordering",
        },
      },
      {
        path: "promotion-codes",
        name: "promotion-codes",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Ordering",
        },
      },
      {
        path: "subscription-manager",
        name: "subscription-manager",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Ordering",
        },
      },
      {
        path: "transaction-charts",
        name: "transaction-charts",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Ordering",
        },
      },
      {
        path: "transaction-reports",
        name: "transaction-reports",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Ordering",
        },
      },
      {
        path: "category-manager",
        name: "category-manager",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Shop Admin",
        },
      },
      {
        path: "item-manager",
        name: "item-manager",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Shop Admin",
        },
      },
      {
        path: "items-sold-report",
        name: "items-sold-report",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Shop Admin",
        },
      },
      {
        path: "location-sales-report",
        name: "location-sales-report",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Shop Admin",
        },
      },
      {
        path: "order-dashboard",
        name: "order-dashboard",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Shop Admin",
        },
      },
      {
        path: "faqs-shop-on-campus",
        name: "faqs-shop-on-campus",
        component: Dashboard,
      },
      {
        path: "pickup-&-delivery",
        name: "pickup-&-delivery",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Shop Admin",
        },
      },
      {
        path: "shop-manager",
        name: "shop-manager",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Shop Admin",
        },
      },
      {
        path: "shop-refunds-report",
        name: "shop-refunds-report",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Shop Admin",
        },
      },
      {
        path: "support-shop-on-campus",
        name: "support-shop-on-campus",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Shop Admin",
        },
      },
      {
        path: "shop-on-campus",
        name: "shop-on-campus",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Shop Admin",
        },
      },
      {
        path: "transaction-reports-(shop)",
        name: "transaction-reports-(shop)",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Shop Admin",
        },
      },
      {
        path: "promotion-manager",
        name: "promotion-manager",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Shop Admin",
        },
      },
      {
        path: "catering-files-manager",
        name: "catering-files-manager",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Content Management",
        },
      },
      {
        path: "dining-blog-articles",
        name: "dining-blog-articles",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Content Management",
        },
      },
      {
        path: "directors-meetings",
        name: "directors-meetings",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Content Management",
        },
      },
      {
        path: "emergency-alerts",
        name: "emergency-alerts",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Content Management",
        },
      },
      {
        path: "events",
        name: "events",
        component: Events,
        meta: {
          type: "campus",
          sub: "Content Management",
        },
      },
      {
        path: "special-offers",
        name: "special-offers",
        component: SpecialOffers,
        meta: {
          type: "campus",
          sub: "Content Management",
        },
      },
      {
        path: "get-answers-now",
        name: "get-answers-now",
        component: GetAnswersNow,
        meta: {
          type: "campus",
          sub: "Content Management",
        },
      },
      {
        path: "news",
        name: "news",
        component: News,
        meta: {
          type: "campus",
          sub: "Content Management",
        },
      },
      {
        path: "images",
        name: "images",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Content Management",
        },
      },
      {
        path: "newsletters",
        name: "newsletters",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Content Management",
        },
      },
      {
        path: "photo-albums",
        name: "photo-albums",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Content Management",
        },
      },
      {
        path: "polls",
        name: "polls",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "Content Management",
        },
      },
      {
        path: "sections/:id?",
        name: "sections",
        component: () =>
          import("@/views/tools/campus/content-management/Sections.vue"),
        meta: {
          type: "campus",
          sub: "Content Management",
        },
      },
      {
        path: "staff",
        name: "staff",
        component: Staff,
        meta: {
          type: "campus",
          sub: "Content Management",
        },
      },
      {
        path: "homepage",
        name: "homepage",
        component: () =>
          import("@/views/tools/campus/content-management/Homepage.vue"),
        meta: {
          type: "campus",
          sub: "Content Management",
        },
      },
      {
        path: "item-categories",
        name: "item-categories",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "",
        },
      },
      {
        path: "items",
        name: "items",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "",
        },
      },
      {
        path: "refund-reports",
        name: "refund-reports",
        component: Dashboard,
        meta: {
          type: "campus",
          sub: "",
        },
      },
    ],
  },
];

// Create Router
const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  linkExactActiveClass: "active",
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
  routes,
});

// Navigation Guards
function handleAuthentication(next, to) {
	const authStore = useAuthStore();
	const user = authStore.getUser();
	if (!user?.token || authStore.isExpired(user.token)) {
		authStore.logout();
		next({
			path: "/login",
			params: { nextUrl: to.fullPath },
		});
	}
}

router.beforeEach(async (to, from, next) => {
  if (to.matched.length == 0) pageNotFound(to, next);

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const session = useAuthStore();
		handleAuthentication(next, to);
    if (
      !session.hasAppAccess(
        to.name,
        to.meta.type,
        to.meta.sub,
        to.meta.appAccessName
      )
    ) {
      userAccessDenied(to, next);
    }
  }
  if (to.matched.some((record) => record.path == "/login")) {
    const authStore = useAuthStore();
    authStore.logout();
  }
  next();
});

function pageNotFound(to, next) {
  return next({
    path: "/404",
    params: { nextUrl: to.fullPath },
  });
}

function userAccessDenied(to, next) {
  return next({
    path: "/403",
    params: { nextUrl: to.fullPath },
  });
}

// NProgress
/*eslint-disable no-unused-vars*/
NProgress.configure({ showSpinner: true });

router.beforeResolve((to, from, next) => {
  NProgress.start();
  next();
});

router.afterEach((to, from) => {
  NProgress.done();
});
/*eslint-enable no-unused-vars*/

export default router;
