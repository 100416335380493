import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBrush,
  faBurgerSoda,
  faChartPieSimple as faChartPieSimpleSolid,
  faCircle as faCircleSolid,
  faDownload,
  faObjectsColumn,
  faReply,
  faShare,
  faSpinnerThird,
} from '@fortawesome/pro-solid-svg-icons'
import {
  faChartNetwork,
  faGlobe,
  faGridDividers,
  faGridRound,
  faPeopleGroup,
  faUser as faUserDuotone,
  faTrash,
} from '@fortawesome/pro-duotone-svg-icons'
import {
  faArrowLeft,
  faArrowRight,
  faArrowUpRightFromSquare,
  faBagShopping,
  faBars,
  faBell,
  faBellConcierge,
  faBookAtlas,
  faBoxBallot,
  faBurgerSoda as faBurgerSodaReg,
  faCalculator,
  faCalendar,
  faCalendarClock,
  faChartBar,
  faChartPieSimple,
  faChartNetwork as faChartNetworkRegular,
  faChartSimple,
  faCheckCircle,
  faChevronDown as faChevronDownReg,
  faCircle,
  faCircleHalfStroke,
  faCircleXmark,
  faClock,
  faComment,
  faCommentQuestion,
  faCommentsQuestionCheck,
  faCopyright,
  faCreditCard as faCreditCardRegular,
  faEllipsisVertical,
  faFileExport,
  faFileImport,
  faFileLock,
  faFileShield,
  faGaugeMax,
  faGear,
  faGlobe as faGlobeRegular,
  faGrid2Plus,
  faGridDividers as faGridDividersRegular,
  faGridRound as faGridRoundRegular,
  faHatChef,
  faHeading,
  faHeartPulse,
  faHorizontalRule,
  faImage,
  faLayerGroup,
  faLifeRing,
  faListCheck,
  faLocationArrow,
  faMap,
  faMagnifyingGlass,
  faMobile as faMobileReg,
  faMoneyBillAlt,
  faMoneyBillTransfer,
  faNewspaper,
  faObjectsColumn as faObjectsColumnRegular,
  faPaintbrush,
  faPalette,
  faPenToSquare,
  faPeopleGroup as faPeopleGroupRegular,
  faQuestionCircle,
  faScreenUsers,
  faSealQuestion,
  faSignal,
  faSliders,
  faSolarSystem,
  faTachometerAverage,
  faTachometerFastest,
  faTachometerSlowest,
  faText,
  faTimes,
  faToolbox,
  faListUl,
  faUserCrown,
  faUser as faUserReg,
  faUsers,
  faUtensilsAlt as faUtensilsReg,
  faVideo,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faAt,
  faBan,
  faBuilding,
  faCalendarAlt,
  faCalendarDay,
  faCalendarEdit,
  faCalendarPlus,
  faChartPieSimpleCircleDollar,
  faChevronCircleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleInfo,
  faCircleCheck,
  faCirclePlus,
  faCommentAltExclamation,
  faCreditCard,
  faClock as faClockLight,
  faCloudDownload,
  faCoffeeTogo,
  faEnvelope,
  faExternalLink,
  faExternalLinkSquare,
  faFileCheck,
  faFilePdf,
  faFire,
  faHeartbeat,
  faIdBadge,
  faIdCard,
  faInputText,
  faListCheck as faListCheckLight,
  faMapMarker,
  faMapMarkerAlt,
  faMobile,
  faMoneyBillAlt as faMoneyBillAltLight,
  faPause,
  faPauseCircle,
  faPencil,
  faPercent,
  faPhoneAlt,
  faPhoneRotary,
  faPlay,
  faPlayCircle,
  faPrint,
  faShieldCheck,
  faShoppingCart,
  faSignIn,
  faSnowflake,
  faSpinnerThird as faSpinnerThirdLight,
  faSun,
  faTag,
  faTimesCircle,
  faToggleOn,
  faTrashAlt,
  faUser,
  faUserEdit,
  faUtensilsAlt as faUtensils,
} from '@fortawesome/pro-light-svg-icons'
import {
  faCcAmex,
  faFacebook,
  faFlickr,
  faInstagram,
  faCcMastercard,
  faPinterest,
  faSnapchat,
  faTiktok,
  faTwitter,
  faVimeo,
  faVimeoV,
  faCcVisa,
  faXTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'

library.add(
  faAt,
  faArrowLeft,
  faArrowRight,
  faArrowUpRightFromSquare,
  faBagShopping,
  faBan,
  faBars,
  faBell,
  faBellConcierge,
  faBookAtlas,
  faBoxBallot,
  faBrush,
  faBuilding,
  faBurgerSoda,
  faBurgerSodaReg,
  faCalculator,
  faCalendar,
  faCalendarAlt,
  faCalendarClock,
  faCalendarDay,
  faCalendarEdit,
  faCalendarPlus,
  faCcAmex,
  faChartBar,
  faChartNetwork,
  faChartNetworkRegular,
  faChartPieSimple,
  faChartPieSimpleCircleDollar,
  faChartPieSimpleSolid,
  faChartSimple,  
  faCheckCircle,
  faChevronCircleRight,
  faChevronDown,
  faChevronDownReg,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleInfo,
  faCircleCheck,
  faCircleHalfStroke,
  faCirclePlus,
  faCircleSolid,
  faCircleXmark,
  faClock,
  faCloudDownload,
  faClockLight,
  faCoffeeTogo,
  faComment,
  faCommentQuestion,
  faCommentsQuestionCheck,
  faCommentAltExclamation,
  faCopyright,
  faCreditCard,
  faCreditCardRegular,
  faDownload,
  faEllipsisVertical,
  faEnvelope,
  faExternalLink,
  faExternalLinkSquare,
  faFacebook,
  faFileCheck,
  faFileExport,
  faFileImport,
  faFileLock,
  faFilePdf,
  faFileShield,
  faFire,
  faFlickr,
  faGaugeMax,
  faGear,
  faGlobe,
  faGlobeRegular,
  faGrid2Plus,
  faGridDividers,
  faGridDividersRegular,
  faGridRound,
  faGridRoundRegular,
  faHatChef,
  faHeading,
  faHeartbeat,
  faHeartPulse,
  faHorizontalRule,
  faIdBadge,
  faIdCard,
  faImage,
  faInputText,
  faInstagram,
  faLayerGroup,
  faLifeRing,
  faListCheck,
  faListCheckLight,
  faLocationArrow,
  faMagnifyingGlass,
  faMap,
  faMapMarker,
  faMapMarkerAlt,
  faCcMastercard,
  faMobile,
  faMobileReg,
  faMoneyBillAlt,
  faMoneyBillAltLight,
  faMoneyBillTransfer,
  faNewspaper,
  faObjectsColumn,
  faObjectsColumnRegular,
  faPaintbrush,
  faPalette,
  faPause,
  faPauseCircle,
  faPencil,
  faPercent,
  faPenToSquare,
  faPeopleGroup,
  faPeopleGroupRegular,
  faPhoneAlt,
  faPhoneRotary,
  faPinterest,
  faPlay,
  faPlayCircle,
  faPrint,
  faQuestionCircle,
  faReply,
  faSealQuestion,
  faShare,
  faShieldCheck,
  faShoppingCart,
  faSignal,
  faScreenUsers,
  faSignIn,
  faSliders,
  faSnowflake,
  faSnapchat,
  faSolarSystem,
  faSpinnerThird,
  faSpinnerThirdLight,
  faSun,
  faTachometerAverage,
  faTachometerFastest,
  faTachometerSlowest,
  faTag,
  faText,
  faTiktok,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faToggleOn,
  faToolbox,
  faTwitter,
  faListUl,
  faUser,
  faUserCrown,
  faUserDuotone,
  faUsers,
  faUserEdit,
  faUserReg,
  faUtensils,
  faUtensilsReg,
  faXmark,
  faVideo,
  faVimeo,
  faVimeoV,
  faCcVisa,
  faXTwitter,
  faYoutube,
) // Use any icon from the Solid style
