/*
 * Example Sales
 */

export default {
	today: [
		{
			href: 'javascript:void(0)',
			icon: 'fa fa-fw fa-plus text-success',
			title: 'New sale! + $249',
			time: '3 min ago',
		},
		{
			href: 'javascript:void(0)',
			icon: 'fa fa-fw fa-plus text-success',
			title: 'New sale! + $129',
			time: '50 min ago',
		},
		{
			href: 'javascript:void(0)',
			icon: 'fa fa-fw fa-plus text-success',
			title: 'New sale! + $119',
			time: '2 hours ago',
		},
		{
			href: 'javascript:void(0)',
			icon: 'fa fa-fw fa-plus text-success',
			title: 'New sale! + $499',
			time: '3 hours ago',
		},
	],
	yesterday: [
		{
			href: 'javascript:void(0)',
			icon: 'fa fa-fw fa-plus text-success',
			title: 'New sale! + $249',
			time: '26 hours ago',
		},
		{
			href: 'javascript:void(0)',
			icon: 'fa fa-fw fa-minus text-danger',
			title: 'Product Purchase - $50',
			time: '28 hours ago',
		},
		{
			href: 'javascript:void(0)',
			icon: 'fa fa-fw fa-plus text-success',
			title: 'New sale! + $119',
			time: '29 hours ago',
		},
		{
			href: 'javascript:void(0)',
			icon: 'fa fa-fw fa-minus text-danger',
			title: 'Paypal Withdrawal - $300',
			time: '37 hours ago',
		},
		{
			href: 'javascript:void(0)',
			icon: 'fa fa-fw fa-plus text-success',
			title: 'New sale! + $129',
			time: '39 hours ago',
		},
		{
			href: 'javascript:void(0)',
			icon: 'fa fa-fw fa-plus text-success',
			title: 'New sale! + $119',
			time: '45 hours ago',
		},
		{
			href: 'javascript:void(0)',
			icon: 'fa fa-fw fa-plus text-success',
			title: 'New sale! + $499',
			time: '46 hours ago',
		},
	],
};
